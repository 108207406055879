import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";

function NoApplications({ title, content: [link1, link2] }) {
  const StyledNoApplicationsWrapper = styled("section")`
      padding: 0 25%;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 10px;
      }
    `,
    StyledTopDiv = styled("div")`
      display: flex;
      margin: 3em 0;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0;
        margin: 1em 0;
      }
    `,
    StyledNoApplicationsTitleContainer = styled("div")`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,

    StyledNoApplicationsDetails = styled("h2")`
      font-size: 24px;
      letter-spacing: -0.62px;
      line-height: 28px;
    `,
    StyledNoApplicationsInternalLink = styled(Link)`
      font-size: 20px;
      text-transform: uppercase;
      text-decoration: underline;
      letter-spacing: -0.28px;
      font-weight: bold;
      display: block;
      margin-top: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 13px;
        line-height: 16px;
      }
    `,
    StyledBottomDiv = styled("div")`
      background-color: ${theme.colors.lightSeaGreen};
      padding: 2em;
      margin: 3em 0;
      p {
        font-family: ${theme.fontFaces.help};
        font-size: 1.25em;
        margin: 1em 0;
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.3px;
          line-height: 18px;
        }
      }
      h4 {
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          font-size: 20px;
          letter-spacing: -0.18px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    `;
  return (
    <StyledNoApplicationsWrapper>
      <StyledTopDiv>
        <StyledNoApplicationsTitleContainer>
          <StyledNoApplicationsDetails>{title}</StyledNoApplicationsDetails>
        </StyledNoApplicationsTitleContainer>
      </StyledTopDiv>
      <StyledBottomDiv>
        <h4>{link1.title}</h4>
        <p>{link1.description.description}</p>
        <StyledNoApplicationsInternalLink
          to={`${link1.link.url}/`}
          className="primary"
        >
          {link1.link.linkText}
        </StyledNoApplicationsInternalLink>
      </StyledBottomDiv>
    </StyledNoApplicationsWrapper>
  );
}

NoApplications.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),

      link: PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      }),
      title: PropTypes.string,
    })
  ),
};

export default NoApplications;
