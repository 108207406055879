import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { theme } from "../../utils/global-styles/theme";

const SearchRichText = ({ richText }) => {
    const Heading2 = styled.h2`
    font-size: 53px;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 64px;
    margin-top: 40px;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 36px;
      letter-spacing: -0.94px;
      line-height: 28px;
    }
  `,
  Heading3 = styled.h3`
  display: block;
  margin: 0 auto;
  font-size: 2em;
  font-family: ${theme.fontFaces.paragraphs};
  font-display: swap;
  text-align: center;
  @media only screen and (max-width: ${theme.breakpoints.helper800}) {
    font-size: 1.2em;
  }
`,
  Heading4 = styled.h4`
    color: ${theme.colors.main};
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.22px;
    line-height: 30px;
    margin-top: 38px;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 20px;
      letter-spacing: -0.18px;
      line-height: 24px;
    }
  `,
  Heading6 = styled.h6`
    color: ${theme.colors.dkBlue};
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 12px;
      line-height: 24px;
    }
  `,
  ListItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    `,
    BulletIconContainer = styled.div`
        height: max-content;
        width: max-content;
        padding-top: 4px;
    `,
    Paragraph = styled.p`
        color: ${theme.colors.main};
        font-size: 20px;
        letter-spacing: -0.57px;
        line-height: 31px;
        margin-top: 25px;

        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 18px;
        letter-spacing: -0.39px;
        line-height: 26px;
        }
    `,
    Wrapper = styled.div`

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-left: 120px;
        margin-right: 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 14px;
        margin-right: 14px;
      }
    `,
    richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: node => {
          return node && node.props ? <b>{node}</b> : <b>{node}</b>;
        },
        [MARKS.ITALIC]: node => {
          return node && node.props ? <i>{node.props}</i> : <i>{node}</i>;
        },
        [MARKS.UNDERLINE]: node => {
          return node && node.props ? (
            <u>{node.props.children}</u>
          ) : (
            <u>{node}</u>
          );
        },
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <a className={"primary"} href={node.data.uri} target="_blank">
            {children}
          </a>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading2 className="primary">{children}</Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading3 className="primary">{children}</Heading3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
        [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <ListItem>
            <BulletIconContainer>
              <BulletIcon />
            </BulletIconContainer>
            {children}
          </ListItem>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return children && children[0] !== "" ? (
            <Paragraph>{children}</Paragraph>
          ) : null;
        },
      },
    };

  return (
    <Wrapper>
      {richText?.content.map((item, index) => {
          return documentToReactComponents(item, richTextOptions);
      })}
    </Wrapper>
  );
};

SearchRichText.propTypes = {
  richText: PropTypes.object,
};

export default SearchRichText;
