import { graphql, useStaticQuery } from "gatsby";

const useApplicationNotificationData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageLayout(
        filter: {
          node_locale: { eq: "en-US" }
          queryIdentifier: { eq: "dashboard-application-notification" }
        }
      ) {
        nodes {
          heading
          contentModules {
            ... on ContentfulTitleDescription {
              title
              description {
                description
              }
              link {
                linkText
                url
              }
            }
          }
        }
      }
    }
  `);

  let notification = data.allContentfulPageLayout.nodes[0].contentModules[0];
  return {
    title: notification.title,
    options: [notification.description.description],
    link: { url: notification.link.url, linkText: notification.link.linkText },
  };
};

export default useApplicationNotificationData;
