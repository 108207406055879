import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";

function NoCourses({ title, link, alreadyEnrolled, locale = "en-US" }) {
  const StyledNoCoursesWrapper = styled("section")`
      padding: 0 25%;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 10px;
      }
    `,
    StyledTopDiv = styled("div")`
      display: flex;
      margin: 3em 0;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0;
        margin: 1em 0;
      }
    `,
    StyledNoCoursesTitleContainer = styled("div")`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,
    StyledCoursesTitle = styled("h1")`
      font-size: 40px;
      letter-spacing: -1.92px;
      line-height: 48px;
      margin-bottom: 30px;
    `,
    StyledNoCoursesDetails = styled("h2")`
      font-size: 24px;
      letter-spacing: -0.62px;
      line-height: 28px;
    `,
    StyledNoCoursesInternalLink = styled(Link)`
      font-size: 20px;
      text-transform: uppercase;
      text-decoration: underline;
      letter-spacing: -0.28px;
      font-weight: bold;
      display: block;
      margin-top: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 13px;
        line-height: 16px;
      }
    `,
    StyledBottomDiv = styled("div")`
      background-color: ${theme.colors.lightSeaGreen};
      padding: 2em;
      margin: 3em 0;
      p {
        font-family: ${theme.fontFaces.help};
        font-size: 1.25em;
        margin: 1em 0;
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.3px;
          line-height: 18px;
        }
      }
      h4 {
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          font-size: 20px;
          letter-spacing: -0.18px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    `;
  return (
    <StyledNoCoursesWrapper>
      <StyledTopDiv>
        <StyledNoCoursesTitleContainer>
          <StyledCoursesTitle>
            {locale === "en-US" ? "Courses" : "Cursos"}
          </StyledCoursesTitle>
          <StyledNoCoursesDetails>
            {title}
            <StyledNoCoursesInternalLink
              to={`${link?.url}/`}
              className="primary"
            >
              {link?.linkText}
            </StyledNoCoursesInternalLink>
          </StyledNoCoursesDetails>
        </StyledNoCoursesTitleContainer>
      </StyledTopDiv>
      <StyledBottomDiv>
        <h4>{alreadyEnrolled?.title}</h4>
        <p>{alreadyEnrolled?.description?.description}</p>
        <StyledNoCoursesInternalLink
          to={`${alreadyEnrolled?.link?.url}?stage=2`}
          className="primary"
        >
          {alreadyEnrolled?.link?.linkText}
        </StyledNoCoursesInternalLink>
      </StyledBottomDiv>
    </StyledNoCoursesWrapper>
  );
}

NoCourses.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  alreadyEnrolled: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    link: PropTypes.shape({
      linkText: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default NoCourses;
