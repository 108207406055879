import React from "react";
import styled from "@emotion/styled";
import Image from "gatsby-image";
import { theme } from "../../../utils/global-styles/theme";

const Wrapper = styled.div``,
  InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  Container = styled.div`
    width: 100%;
    height: 374px;
    position: relative;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      height: 450px;
    }
  `,
  Title = styled.h2``,
  Description = styled.div`
    font-family: ${theme.fontFaces.paragraphs};
    font-size: 26px;
    margin-top: 20px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 20px;
    }
  `,
  Link = styled.div`
    margin-top: 30px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 35px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      right: -61px;
    }
  `,
  LinkText = styled.a`
    text-decoration: none;
    display: block;
    float: right;
    border-bottom: solid white 4px;
    font-size: 24px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      float: left;
    }
  `,
  Content = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 78px 20%;

    @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
        .breakpoints.desktopUp}) {
      padding: 78px 57px;
    }

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      padding: 20px 62px;
    }
  `,
  ImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
  `;

const FoundryConversations = ({ foundryConversations }) => {
  const showConversations = () => {
    return foundryConversations?.map(item => {
      return (
        <Container>
          <ImageWrapper>
            <StyledImage fluid={item?.image?.fluid} />
          </ImageWrapper>
          <Content>
            <Title className="white">{item.title}</Title>
            <Description className="white">
              {item.description.description}
            </Description>
            <Link>
              <LinkText href={item?.link?.url} className="white">
                {item?.link?.linkText}
              </LinkText>
            </Link>
          </Content>
        </Container>
      );
    });
  };

  return (
    <Wrapper>
      <InnerWrapper>{showConversations()}</InnerWrapper>
    </Wrapper>
  );
};

export default FoundryConversations;
