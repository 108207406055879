import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

const {
    colors: { primary, secondary },
  } = theme,
  StyledG = styled("g")`
    fill: ${primary};
    stroke :${primary};
    &:hover {
      cursor: pointer;
    }
  `,
  SortIcon = ({ width, height }) => {
    return (
      <svg
        width={width || "14px"}
        height={height || "11px"}
        viewBox="0 0 14 11"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1.4"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <StyledG
            id="Icon/Sort"
            transform="translate(-732.000000, -276.000000)"
            stroke="#002F71"
            stroke-width="2"
          >
            <g id="Icon/Sort1" transform="translate(733.000000, 277.000000)">
              <g id="Icon/Sort2">
                <line x1="5.5" y1="4.5" x2="11.5" y2="4.5" id="Line"></line>
                <line x1="0.5" y1="0.5" x2="11.5" y2="0.5" id="Line"></line>
                <line x1="8.5" y1="8.5" x2="11.5" y2="8.5" id="Line"></line>
              </g>
            </g>
          </StyledG>
        </g>
      </svg>
    );
  };

SortIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SortIcon;
