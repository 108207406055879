import { graphql, useStaticQuery } from "gatsby";

const useMemberSearchData = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulCommunityMemberSearch(
        queryIdentifier: { eq: "community-member-search" }
      ) {
        heading
        foundryDescription {
          json
        }
        communityDescription {
          json
        }
        foundryEmbedLink
        communityEmbedLink
      }
    }
  `);

  return data.contentfulCommunityMemberSearch;
};

export default useMemberSearchData;
