import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import Foundry from "../../Community/Foundry";
import UserSliderOverlay from "../../shared/SliderOverlay/UserSliderOverlay";

const Wrapper = styled.div`
    margin-bottom: 70px;

    @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
        .breakpoints.desktopUp}) {
      margin-bottom: 57px;
    }

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      margin-bottom: 0px;
    }
  `,
  Heading = styled.h1`
  margin-left: 14px;
  margin-right: 14px;

  @media only screen and (min-width: ${theme.breakpoints.mobile}) {
    margin-left: 20%;
    margin-right: 20%;
  }
  `;

const FoundryTeam = ({
  foundryTeamHeading,
  foundryTeamDescription,
  foundryTeamMembers,
}) => {
  return (
    <Wrapper>
      <Heading>{foundryTeamHeading}</Heading>
      <Foundry richText={foundryTeamDescription?.foundryTeamDescription} />
      <UserSliderOverlay data={foundryTeamMembers} />
    </Wrapper>
  );
};

export default FoundryTeam;
