import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { connect } from "react-redux";
import { theme } from "../../utils/global-styles/theme";
import CancelIcon from "./Icons/cancel-x";
import {
  setSearchCriteria,
  setFiltersAreDisabled,
  resetSearchCourses,
} from "../../state/actions";

const Search = ({ allCourses, dispatch }) => {
  const searchInputRef = React.createRef();

  return (
    <div
      css={css`
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <input
        data-testId="searchBar"
        ref={searchInputRef}
        css={css`
          width: 100%;
          border: none;
          border-bottom: 2px solid ${theme.colors.accent};
          margin-bottom: 16px;
          line-height: 2em;
          font-size: 1.5em;
          font-weight: 300;
          caret-color: ${theme.colors.main};
          &:focus {
            outline: none;
          }
          ::placeholder {
            color: ${theme.colors.main};
            font-weight: 200;
          }
        `}
        placeholder="Search the course catalog"
        onChange={e => {
          const searchTerm = e.target.value.toLowerCase().trim(),
            matches = allCourses.filter(course => {
              if (
                course.title &&
                course.title.toLowerCase().includes(searchTerm)
              ) {
                return true;
              } else if (
                course.aboutTheInstructors &&
                Array.isArray(course.aboutTheInstructors) &&
                course.aboutTheInstructors.find(instructor =>
                  instructor.name.toLowerCase().includes(searchTerm)
                )
              ) {
                return true;
              } else {
                return false;
              }
            });
          e.target.value.length === 0
            ? dispatch(setFiltersAreDisabled(false))
            : dispatch(setFiltersAreDisabled(true));
          dispatch(setSearchCriteria(matches));
        }}
        className="primary"
        defaultValue={""}
      />
      <CancelIcon
        styles={`position: relative; right: 16px;`}
        handleClick={e => {
          searchInputRef.current.value = "";
          dispatch(resetSearchCourses());
          dispatch(setFiltersAreDisabled(false));
        }}
      />
    </div>
  );
};

Search.propTypes = {
  allCourses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      courseId: PropTypes.string,
      courseCost: PropTypes.string,
      courseStartDate: PropTypes.string,
      courseDuration: PropTypes.string,
      courseType: PropTypes.string,
      shortDescription: PropTypes.shape({
        shortDescription: PropTypes.string,
      }),
      courseImage: PropTypes.shape({
        fluid: PropTypes.shape({ srcSet: PropTypes.string }),
      }),
      theme: PropTypes.shape({
        theme: PropTypes.string,
      }),
    })
  ),
  dispatch: PropTypes.func,
};

export default connect(
  state => ({
    allCourses: state.courseReducer.allCourses,
  }),
  null
)(Search);
