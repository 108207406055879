import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

const {
    colors: { primary, secondary, main },
  } = theme,
  StyledG = styled("g")`
    fill: ${main};
    &:hover {
      cursor: pointer;
    }
  `,
  AddCoursePlusIcon = ({ width, height }) => {
    return (
      <svg
        width={width || "18px"}
        height={height || "18px"}
        viewBox="0 0 18 18"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <StyledG
            id="Icon/New-Course"
            transform="translate(-1.000000, -1.000000)"
            fillRule="nonzero"
          >
            <path
              d="M10,1 C5.032,1 1,5.032 1,10 C1,14.968 5.032,19 10,19 C14.968,19 19,14.968 19,10 C19,5.032 14.968,1 10,1 Z M13.1,10.4 L10.4,10.4 L10.4,13.1 C10.4,13.595 9.995,14 9.5,14 C9.005,14 8.6,13.595 8.6,13.1 L8.6,10.4 L5.9,10.4 C5.405,10.4 5,9.995 5,9.5 C5,9.005 5.405,8.6 5.9,8.6 L8.6,8.6 L8.6,5.9 C8.6,5.405 9.005,5 9.5,5 C9.995,5 10.4,5.405 10.4,5.9 L10.4,8.6 L13.1,8.6 C13.595,8.6 14,9.005 14,9.5 C14,9.995 13.595,10.4 13.1,10.4 Z"
              id="Shape"
            ></path>
          </StyledG>
        </g>
      </svg>
    );
  };

AddCoursePlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default AddCoursePlusIcon;
