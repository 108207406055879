import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import BulletIcon from "../shared/BlurredContentContainer/Assets/BulletIcon";

import { theme } from "../../utils/global-styles/theme";

const Foundry = ({ richText }) => {
  const Heading2 = styled.h2`
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      line-height: 64px;
      margin-top: 40px;


      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 36px;
        letter-spacing: -0.94px;
        line-height: 28px;
      }
    `,
    Heading3 = styled.h3`
      display: block;
      margin: 0 auto;
      font-size: 2em;
      font-family: ${theme.fontFaces.paragraphs};
      font-display: swap;
      text-align: center;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 1.2em;
      }
    `,
    Heading4 = styled.h4`
      color: ${theme.colors.main};
      font-size: 25px;
      font-weight: bold;
      letter-spacing: -0.22px;
      line-height: 25px;
      letter-spacing: -0.82px;

      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        letter-spacing: -0.82px;
        font-size: 25px;
        line-height: 25px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        letter-spacing: -0.82px;
        font-size: 25px;
        line-height: 25px;
      }
    `,
    Heading6 = styled.h6`
      color: ${theme.colors.dkBlue};
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 12px;
        line-height: 24px;
      }
    `,
    ListItem = styled.div`
      display: flex;
      flex-direction: row;
      margin-top: 25px;
    `,
    BulletIconContainer = styled.div`
      height: max-content;
      width: max-content;
      padding-top: 4px;
    `,
    Paragraph = styled.p`
      color: ${theme.colors.main};
      font-size: 20px;
      letter-spacing: -0.57px;
      line-height: 31px;
      margin-top: 25px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 18px;
        letter-spacing: -0.39px;
        line-height: 26px;
      }
    `,
    Wrapper = styled.div`
      margin-left: 20%;
      margin-right: 20%;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-left: 120px;
        margin-right: 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 14px;
        margin-right: 14px;
      }
    `,
    SpecialLinksWrapper = styled.div`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 10px;
      margin-bottom: 30px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template-columns: 1fr 1fr;
      }
    `,
    SpecialLink = styled.a`
      text-decoration: underline;
      margin-top: 10px;
      margin-bottom: 10px;
    `,
    richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: node => {
          return node && node.props ? <b>{node}</b> : <b>{node}</b>;
        },
        [MARKS.ITALIC]: node => {
          return node && node.props ? <i>{node.props}</i> : <i>{node}</i>;
        },
        [MARKS.UNDERLINE]: node => {
          return node && node.props ? (
            <u>{node.props.children}</u>
          ) : (
            <u>{node}</u>
          );
        },
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <a className={"primary"} href={node.data.uri}>
            {children}
          </a>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading2 >{children}</Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading3 className="primary">{children}</Heading3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
        [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <ListItem>
            <BulletIconContainer>
              <BulletIcon />
            </BulletIconContainer>
            {children}
          </ListItem>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return children && children[0] !== "" ? (
            <Paragraph>{children}</Paragraph>
          ) : null;
        },
      },
    };
  const richTextNodes = JSON.parse(richText).content;

  const specialLinks = links => {
    return links.map((item, index) => {
      if (item.nodeType === "embedded-entry-inline") {
        return (
          <SpecialLink
            className={"primary"}
            href={item.data.target.fields.url["en-US"]}
          >
            {item.data.target.fields.linkText["en-US"]}
          </SpecialLink>
        );
      }
    });
  };

  return (
    <Wrapper>
      {richTextNodes.map((item, index) => {
        if (item?.content[1]?.nodeType === "embedded-entry-inline") {
          return (
            <SpecialLinksWrapper>
              {specialLinks(item.content)}
            </SpecialLinksWrapper>
          );
        } else {
          return documentToReactComponents(item, richTextOptions);
        }
      })}
    </Wrapper>
  );
};

Foundry.propTypes = {
  richText: PropTypes.object,
};

export default Foundry;
