import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import SearchRichText from "./SearchRichText";

const Heading = styled.h1`
  
  `,
  Wrapper = styled.section`
    margin-left: 20%;
    margin-right: 20%;

    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      margin-left: 120px;
      margin-right: 120px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin-left: 14px;
      margin-right: 14px;
    }
  `,
  Search = ({ heading, description, link }) => {
    return (
      <>
        <Wrapper>
          <Heading>{heading}</Heading>
          <SearchRichText richText={description?.json} />
          <iframe
            title="member search iframe"
            class="airtable-embed"
            src={link}
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="533"
            style={{
              background: "transparent",
              border: "1px solid #ccc",
              marginTop: "4em",
            }}
          />
        </Wrapper>
      </>
    );
  };

Search.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

export default Search;
