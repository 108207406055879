import { graphql, useStaticQuery } from "gatsby";

const useCommunityEventsData = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "community-events-dashboard" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          upcomingEventsHeading: heading
          pastEventsHeading: shortDescription
          registeredEventText: additionalText
          contentModules {
            ... on ContentfulLink {
              sectionIdentifier
              linkText
              url
            }
          }
        }
      }
    }
  `);

  return data.allContentfulPageLayout.nodes[0];
};

export default useCommunityEventsData;
