import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../utils/global-styles/theme";
import PrimaryButton from "../../components/shared/PrimaryButton/PrimaryButton";
import NoApplications from "./NoApplications";
import SortIcon from "./Icons/SortIcon";
import CheckedIcon from "./Icons/CheckedIcon";
// import useDashboardNoApplicationData from "../../hooks/no-application-data";

const DashboardApplication = ({
  isEvaluator,
  applications,
  applicationData,
}) => {
  const [sortAsc, setSortAsc] = useState(false),
    [isMobileView, setMobileView] = useState(false),
    [sortedApplications, setSortedApplications] = useState([...applications]),
    handleResize = () => {
      setMobileView(window.innerWidth < 1201);
    };
  const noApplicationData = applicationData,
    GotoSurveyMonkey = sm_url => {
      if (typeof window === "object") {
        let language = navigator.language || navigator.userLanguage;
        language = language?.includes("es") ? "es" : "en";
        const relayState = `${process.env.GATSBY_sm_prog_url}${sm_url}?lang=${language}`,
          url = `https://login.acumenacademy.org/app/saml2/idp/SSOService.php?spentityid=https://acumenacademy.smapply.io/saml2/metadata/&next=${relayState}`;
        return (window.location.href = `${url}`);
      }
      return false;
    };
  useEffect(() => {
    // If executed server-side (no window object) it will return false(exit) out of the useEffect() hook
    if (typeof window !== "object") return false;
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [applications]);
  const Title = styled("h1")`
    display: none;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      font-size: 32px;
      letter-spacing: -0.83px;
      display: block;
      line-height: 38px;
      margin-top: 30px;
      margin-left: 0.7em;
    }
  `;

  const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    @media only screen and (min-width: ${theme.breakpoints.tabletLandscapeUp}) {
      padding: 0 4em;
    }
  `;

  const Table = styled.div`
    display: table;
    width: 100%;
  `;

  const TableRow = styled.div`
    display: table-row;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      &:not(:first-of-type) {
        display: block;
        padding: 1em 0;
        background-color: ${theme.colors.lightSeaGreen};
      }
    }
  `;

  const TableHeader = styled.div`
    display: table-cell;
    font-weight: bold;
    font-size: 17px;
    font-family: ${theme.fontFaces.headers};
    padding: 1.2em 5em;
    padding-left: 1.2em;
    border-bottom: 1px solid #ddd;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
  `;

  const TableCell = styled.div`
      display: table-cell;
      padding: 1.2em 5em;
      padding-left: 1.2em;
      border-bottom: 1px solid ${theme.colors.lightGray};
      text-align: left;

      @media (max-width: 600px) {
        position: relative;
        padding: 0 20px;
        text-align: left;
        display: flex;
        border-bottom: none;

        &::before {
          content: attr(data-label);
          position: absolute;
          left: 0;
          width: 50%;
          padding-left: 8px;
          white-space: nowrap;
          text-align: left;
          font-weight: bold;
        }
        button {
          margin-top: 10px;
        }
      }
    `,
    StyledButton = styled.button`
      background-color: transparent;
      width: auto;
      padding: 10px 50px;
      display: block;
      margin-top: 12px;
      font-family: ${theme.fontFaces.headers};
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: ${theme.colors.accent};
        cursor: not-allowed;
        transition-timing-function: ease-in;
        transition: 0.25s;
      }
      &:enabled {
        transition-timing-function: ease-out;
        transition: 0.25s;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-bottom: 1.25em;
        width: 13em;
        font-size: 12px;
      }
    `;

  const sortApplications = () => {
    setSortAsc(!sortAsc);
    if (!sortAsc) {
      const sortedData = applications.sort((a, b) =>
        new Date(b.closing_date) < new Date(a.closing_date) ? 1 : -1
      );

      setSortedApplications(sortedData);
    } else {
      const sortedData = applications.sort((a, b) =>
        new Date(a.closing_date) < new Date(b.closing_date) ? 1 : -1
      );

      setSortedApplications(sortedData);
    }
  };

  return (
    <>
      {!applications.length ? (
        <NoApplications
          title={noApplicationData.heading}
          content={noApplicationData.contentModules}
        />
      ) : (
        <>
          <Title>Applications</Title>
          <TableContainer>
            <Table>
              <TableRow>
                <TableHeader className="primary">Application Name</TableHeader>
                <TableHeader className="primary">Registration Date</TableHeader>
                <TableHeader onClick={sortApplications} className="primary">
                  Registration Close Date <SortIcon />
                </TableHeader>
                <TableHeader className="primary">Status</TableHeader>
              </TableRow>

              {sortedApplications.map((application, index) => (
                <TableRow key={index}>
                  <TableCell>{application.name}</TableCell>
                  <TableCell>{application.registration_date}</TableCell>
                  <TableCell>{application.closing_date}</TableCell>
                  <TableCell>
                    {" "}
                    {isMobileView ? (
                      application.status === "Complete" ? (
                        <CheckedIcon />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {application.status}
                  </TableCell>
                  {!application.resume ? (
                    <TableCell>
                      <PrimaryButton
                        classNames={"black black-border"}
                        text="View Application"
                        handleClick={() => GotoSurveyMonkey(application.sm_url)}
                        styles={css`
                          width: auto;
                          white-space: nowrap;
                          @media only screen and (max-width: ${theme.breakpoints
                              .mobile}) {
                            margin-bottom: 1.25em;
                            font-size: 12px;
                            width: 13em;
                          }
                        `}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <StyledButton
                        className="black-button"
                        onClick={() => GotoSurveyMonkey(application.sm_url)}
                      >
                        Resume
                      </StyledButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

DashboardApplication.propTypes = {
  isEvaluator: PropTypes.bool,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
      registration_date: PropTypes.string,
      closing_date: PropTypes.string,
      sm_url: PropTypes.string,
      has_closed: PropTypes.bool,
    })
  ),
};

export default DashboardApplication;
