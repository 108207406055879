import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import VideoPreview from "../CourseDetail/child-components/VideoPreview";
import PrimaryButton from "../shared/PrimaryButton/PrimaryButton";
import Foundry from "./Foundry";
import SliderOverlay from "../shared/SliderOverlay/SliderOverlay";
import { theme } from "../../utils/global-styles/theme";
import Events from "./Events";
import Search from "./Search";
import useMemberSearchData from "../../hooks/member-search-data";
import FoundryTeam from "./child-components/FoundryTeam";
import FoundryConversations from "./child-components/FoundryConversations";
import CourseBenefits from "../CourseDetail/child-components/CourseBenefits";

const Title = styled.h1`
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-size: 66px;
    line-height: 73px;
    font-display: swap;
    font-weight: bold;
    letter-spacing: -3.2px;

    @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
        .breakpoints.desktopUp}) {
      line-height: 52px;
      font-size: 52px;
      letter-spacing: -2.08px;
    }

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 70px;
      line-height: 70px;
      letter-spacing: -2.8px;
    }
  `,
  SliderWrapper = styled.div`
    margin-top: 20%;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      margin-top: 100px;
    }
  `,
  ImagePreviewContainer = styled.div`
      width: 60%;
      padding-top: 33.75%;
      height: 0px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 20px;

        @media only screen and (max-width: ${theme.breakpoints.mobile}) {
          width: 90%;
          padding-top: 50.625%;
        }
      }
  `,
  ImagePreview = styled.div`
    background-image: url('${props => props.background}');
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
  `,
  RichTextWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  Community = ({
    userDetails,
    upcomingEvents,
    pastEvents,
    communityDashboardData,
    locale,
  }) => {
    const [isCommunity, setIsCommunity] = useState(false),
      [isFoundry, setIsFoundry] = useState(false);

    useEffect(() => {
      if (userDetails?.user_type?.includes("Foundry")) {
        setIsFoundry(true);
        setIsCommunity(true);
      } else if (
        userDetails?.user_type?.includes("Community") &&
        !userDetails.user_type.includes("Foundry")
      ) {
        setIsCommunity(true);
        setIsFoundry(false);
      } else if (
        userDetails?.user_type?.includes("Fellow") &&
        !userDetails.user_type.includes("Community") &&
        !userDetails.user_type.includes("Foundry")
      ) {
        setIsCommunity(false);
        setIsFoundry(false);
      }
    });

    const {
        heading: searchHeading,
        foundryDescription: foundryDescription,
        communityDescription: communityDescription,
        foundryEmbedLink,
        communityEmbedLink,
      } = useMemberSearchData(),
      userType = userDetails?.user_type,
      {
        heading,
        headingFoundry,
        heroLinks,
        contentModules,
        heroVideoThumbnailCommunityMember,
        heroVideoThumbnailCommunityNonMember,
        heroVideoCommunityMember,
        heroVideoCommunityNonMember,
        foundryStoriesHeader,
        foundryStoriesSectionLink,
        foundryStoriesSlider,
        foundryTeamHeading,
        foundryTeamDescription,
        foundryTeamMembers,
        foundryConversations,
      } = communityDashboardData,
      communityMember = heroLinks.filter(heroLink => {
        return heroLink.sectionIdentifier === "community-member";
      }),
      nonCommunityMember = heroLinks.filter(heroLink => {
        return heroLink.sectionIdentifier === "non-community-member";
      }),
      richTextContent = contentModules.filter(contentModule => {
        return contentModule.sys.contentType.sys.id === "richTextModule";
      }),
      courseBenefits = contentModules.filter(contentModule => {
        return contentModule.sys.contentType.sys.id === "benefitsCard";
      }),
      upcomingCourses = contentModules.filter(contentModule => {
        return contentModule.sys.contentType.sys.id === "upcomingCourses";
      }),
      navigateOnHeroLink = () => {
        let navigateUrl = isCommunity
          ? communityMember[0]?.url
          : nonCommunityMember[0]?.url;
        if (typeof window !== "undefined") {
          window.open(navigateUrl);
        }
      };

    return (
      <>
        <Title>{isFoundry ? headingFoundry : heading}</Title>
        <RichTextWrapper>
          {isFoundry
            ? richTextContent[1]?.richText && (
                <Foundry richText={richTextContent[1]?.richText?.richText} />
              )
            : richTextContent[0]?.richText && (
                <Foundry richText={richTextContent[0]?.richText?.richText} />
              )}

          {isFoundry
            ? richTextContent[3]?.richText && (
                <Foundry richText={richTextContent[3]?.richText?.richText} />
              )
            : richTextContent[2]?.richText && (
                <Foundry richText={richTextContent[2]?.richText?.richText} />
              )}
        </RichTextWrapper>
        {isCommunity ? (
          <VideoPreview
            videoPreviewUrl={heroVideoCommunityMember.url}
            title=""
            videoThumbnail={heroVideoThumbnailCommunityMember}
            previewText="Watch the Recording"
            hasGrayBackground={false}
          />
        ) : heroVideoCommunityNonMember?.url ? (
          <VideoPreview
            videoPreviewUrl={heroVideoCommunityNonMember.url}
            title=""
            videoThumbnail={heroVideoThumbnailCommunityNonMember}
            previewText="Watch the Recording"
            hasGrayBackground={false}
          />
        ) : (
          <ImagePreviewContainer>
            <ImagePreview
              background={heroVideoThumbnailCommunityNonMember?.file?.url}
            />
          </ImagePreviewContainer>
        )}
        {!isFoundry && !isCommunity && (
          <button
            css={css`
              margin: 0 auto;
              margin-bottom: 20px;
              display: block;
            `}
            className="black-button"
            onClick={() => navigateOnHeroLink()}
          >
            {isCommunity
              ? communityMember[0]?.linkText || "Connect in Slack"
              : nonCommunityMember[0]?.linkText || "Sign up for the community"}
          </button>
        )}
        <Events
          pastEvents={pastEvents}
          upcomingEvents={upcomingEvents}
          userType={userType}
        />

        {(isCommunity || isFoundry) && (
          <Search
            heading={searchHeading}
            description={isFoundry ? foundryDescription : communityDescription}
            link={isFoundry ? foundryEmbedLink : communityEmbedLink}
          />
        )}
        {isFoundry && (
          <FoundryTeam
            foundryTeamHeading={foundryTeamHeading}
            foundryTeamDescription={foundryTeamDescription}
            foundryTeamMembers={foundryTeamMembers}
          />
        )}

        {isFoundry && (
          <FoundryConversations foundryConversations={foundryConversations} />
        )}

        {isCommunity && !isFoundry && courseBenefits && (
          <CourseBenefits
            css={{
              marginBottom: "50px",
              marginTop: "50px",
              [`@media only screen and (min-width: ${theme.breakpoints.mobile})`]: {
                marginBottom: "120px",
                marginTop: "50px",
                paddingTop: "50px",
                height: "auto",
              },
            }}
            courseBenefits={courseBenefits[0]}
            page={"community"}
          />
        )}
        <SliderWrapper>
          {!isFoundry ? (
            <SliderOverlay
              data={upcomingCourses[0].courses}
              contentMultiple={3}
              arrowIncrementDecrement={3}
              title={upcomingCourses[0].title}
              titleOverlap={"55px"}
              titleOverlapMobile={"15px"}
              arrowBgColorClassName="white"
            />
          ) : (
            <SliderOverlay
              data={foundryStoriesSlider}
              contentMultiple={3}
              arrowIncrementDecrement={3}
              title={foundryStoriesHeader}
              titleOverlap={"55px"}
              titleOverlapMobile={"15px"}
              arrowBgColorClassName="white"
              ctaText={foundryStoriesSectionLink.linkText}
              ctaUrl={foundryStoriesSectionLink.url}
              locale={locale}
            />
          )}
        </SliderWrapper>
      </>
    );
  };

export default Community;
