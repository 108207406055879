import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

const {
    colors: { primary},
  } = theme,
  StyledRect = styled("rect")`
    fill: ${primary};
    stroke: ${primary};
    &:hover {
      cursor: pointer;
    }
  `,
  SortIcon = ({ width, height }) => {
    return (
      <svg
        width={width || "16px"}
        height={height || "12px"}
        viewBox="0 0 16 12"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <g
            id="Checked/Chec"
            fill ="transparent"
            stroke= "transaprent"
            transform="translate(-935.000000, -423.000000)"
          >
            <g id="Icon/Check1" transform="translate(932.000000, 419.000000)">
              <g id="Icon/Sort2">
                <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
                <StyledRect
                  id="Rectangle"
                  fill-rule="evenodd"
                  transform="translate(7.353553, 11.646447) rotate(-315.000000) translate(-7.353553, -11.646447) "
                  x="3.35355339"
                  y="10.1464466"
                  width="8"
                  height="3"
                />
                <StyledRect
                  id="Rectangle-Copy"
                  fill="green"
                  fill-rule="evenodd"
                  transform="translate(13.000000, 9.500000) rotate(-225.000000) translate(-13.000000, -9.500000) "
                  x="7"
                  y="8"
                  width="12"
                  height="3"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

SortIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SortIcon;
