import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../utils/global-styles/theme";
import { css } from "@emotion/core";

function CertificateIcon({ width, height }) {
  return (
    <svg
      width={width || "30px"}
      height={height || "30px"}
      viewBox="0 0 27 27"
      css={css`
        g {
          fill: ${theme.colors.primary};
        }
      `}
    >
      <g id="icon/certificate/primary">
        <rect id="Rectangle" x="0" y="0"></rect>
        <path
          d="M10.5,11.2916667 C8.30182232,11.2916667 6.51366743,9.59083333 6.51366743,7.5 C6.51366743,5.40916667 8.30182232,3.70833333 10.5,3.70833333 C12.6981777,3.70833333 14.4863326,5.40916667 14.4863326,7.5 C14.4863326,9.59083333 12.6981777,11.2916667 10.5,11.2916667 M16.1947608,7.5 C16.1947608,7.136 16.1537585,6.78283333 16.0820046,6.43941667 L17,5.4915 L15.6571754,5.23366667 C15.3428246,4.58908333 14.9009112,4.01491667 14.3587699,3.53825 L14.5170843,2.2415 L13.2596811,2.78858333 C12.6287016,2.45383333 11.929385,2.235 11.1799544,2.14941667 L10.5,1 L9.82004556,2.14941667 C9.07061503,2.235 8.37129841,2.45383333 7.74031891,2.78858333 L6.48291572,2.2415 L6.64123007,3.53825 C6.09908884,4.01491667 5.6571754,4.58908333 5.3428246,5.23366667 L4,5.4915 L4.91799544,6.43941667 C4.84624146,6.78283333 4.80523918,7.136 4.80523918,7.5 C4.80523918,7.864 4.84624146,8.21716667 4.91799544,8.56058333 L4,9.5085 L5.3428246,9.76633333 C5.6571754,10.412 6.09908884,10.9850833 6.64123007,11.46175 L6.82687927,11.61775 L5.94419134,18.3333333 L10.5,14 L15.0558087,18.3333333 L14.1731207,11.61775 L14.3587699,11.46175 C14.9009112,10.9850833 15.3428246,10.412 15.6571754,9.76633333 L17,9.5085 L16.0820046,8.56058333 C16.1537585,8.21716667 16.1947608,7.864 16.1947608,7.5 L10.8341686,8.56058333"
          fillRule="evenodd"
        ></path>
        <path
          d="M10.5,5.33333333 C9.30573333,5.33333333 8.33333333,6.30486667 8.33333333,7.5 C8.33333333,8.69513333 9.30573333,9.66666667 10.5,9.66666667 C11.6942667,9.66666667 12.6666667,8.69513333 12.6666667,7.5 C12.6666667,6.30486667 11.6942667,5.33333333 10.5,5.33333333"
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

CertificateIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CertificateIcon;
