import { graphql, useStaticQuery } from "gatsby";

const useEnrollmentNotificationData = () => {
  const data = useStaticQuery(graphql`
    query {
      notification: allContentfulTitleDescription(
        filter: { name: { eq: "NovoEd Confirmation" } }
      ) {
        nodes {
          title
          options
          link {
            linkText
            url
          }
        }
      }
    }
  `);
  return data.notification.nodes[0];
};

export default useEnrollmentNotificationData;
