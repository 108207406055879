import { graphql, useStaticQuery } from "gatsby";

const useDashboardData = () => {
  const data = useStaticQuery(graphql`
    query {
      dashboard: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Dashboard 2.0" } }
      ) {
        nodes {
          contentModules {
            ... on ContentfulTitleDescription {
              title
              description {
                description
              }
              options
              link {
                linkText
                url
              }
            }
            ... on ContentfulLink {
              linkText
              url
            }
            ... on ContentfulCarouselContent {
              heading
              cta {
                linkText
                url
              }
              carouselItems {
                ... on ContentfulCourseDetailPage {
                  theme: theme20 {
                    theme
                    themeNumber
                  }
                  title
                  courseCost
                  courseDuration
                  courseAvailability
                  courseId
                  slug
                  productType: courseType
                  courseImage {
                    fluid {
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
            }
            ... on ContentfulUpcomingCoursesFeaturedBlogs {
              heading
              courses {
                ... on ContentfulBlogDetailPage {
                  theme {
                    theme
                    themeNumber
                  }
                  title
                  urlCtaText
                  url
                  blogImage {
                    fluid {
                      aspectRatio
                      srcSet
                    }
                    description
                  }
                }
              }
              linkText
              url
            }
          }
          marketingModal {
            ... on ContentfulMarketingModal {
              id
              cta {
                linkText
                url
              }
              description {
                description
              }
              title
              showAfterPercent
              hubspotListId
              marketingModalImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
              }
              formFields
              successTitle
              successDescription
              additionalText {
                json
              }
            }
          }
        }
      }
    }
  `);
  return data.dashboard.nodes[0];
};

export default useDashboardData;
