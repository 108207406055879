import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";

import useCommunityEventsData from "../../hooks/community-events-data";

import EventAccordion from "./child-components/EventAccordion";

const Heading = styled.h1`
    padding-left: 1.2em;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      padding-left: 0.2em;
    }
    @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
      ${theme.paddings.tabletUpLR}
    }
    @media only screen and (min-width: ${theme.breakpoints
        .tabletLandscapeUp}) and (max-width: ${theme.breakpoints.desktopUp}) {
      ${theme.paddings.tabletLandscapeUpLR}
    }
  `,
  EventContainer = styled.section`
    margin-bottom: 70px;
  `,
  ViewMoreText = styled.h5`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 1em;
    cursor: pointer;
    outline: none;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
    }
  `,
  Events = ({ upcomingEvents, pastEvents, userType }) => {
    const [eventLimit, setEventLimit] = useState(5),
      isFoundry = userType?.includes("Foundry"),
      {
        upcomingEventsHeading,
        pastEventsHeading,
        registeredEventText,
        contentModules,
      } = useCommunityEventsData(),
      nonCommunityEventLink = contentModules.filter(
        link => link.sectionIdentifier === "non-community-member-event-link"
      );

    if (!isFoundry) {
      upcomingEvents = upcomingEvents.filter(
        event => event.level !== "FOUNDRY"
      );
      pastEvents = pastEvents.filter(event => event.level !== "FOUNDRY");
    }
    return (
      <>
        {upcomingEvents.length > 0 && (
          <EventContainer>
            <Heading>{upcomingEventsHeading}</Heading>
            {upcomingEvents
              .filter((event, index) => index < eventLimit)
              .map((event, index) => {
                return (
                  <EventAccordion
                    key={index}
                    index={index}
                    title={event.title}
                    description={event.description}
                    date={event.date}
                    link={event.registration_link}
                    registered={event.registered}
                    registeredEventText={registeredEventText}
                    isUpcoming={true}
                    nonCommunityEventLink={nonCommunityEventLink}
                    userType={userType}
                    buttonText={event.button_text}
                    eventLocation={event.eventLocation}
                  />
                );
              })}
            {upcomingEvents.length >= eventLimit && upcomingEvents.length > 5 && (
              <ViewMoreText
                className="primary secondary-hover"
                onClick={e => {
                  e.preventDefault();
                  setEventLimit(eventLimit + 5);
                }}
                tabIndex="0"
              >
                View More
              </ViewMoreText>
            )}
            {eventLimit >= upcomingEvents.length && upcomingEvents.length > 5 && (
              <ViewMoreText
                className="primary secondary-hover"
                onClick={() => setEventLimit(5)}
                tabIndex="0"
              >
                View Less
              </ViewMoreText>
            )}
          </EventContainer>
        )}
        {/* <EventContainer>
          <Heading>{pastEventsHeading}</Heading>
          {pastEvents.map((event, index) => {
            return (
              <EventAccordion
                key={index}
                index={index}
                title={event.title}
                description={event.description}
                date={event.date}
                link={event.registration_link}
                registered={event.registered}
                registeredEventText={registeredEventText}
                isUpcoming={false}
              />
            );
          })}
        </EventContainer> */}
      </>
    );
  };

Events.propTypes = {
  upcomingEvents: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.string,
    registered: PropTypes.bool,
    registration_link: PropTypes.string,
  }),
  pastEvents: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.string,
    registered: PropTypes.bool,
    registration_link: PropTypes.string,
  }),
  userType: PropTypes.arrayOf(PropTypes.string),
};

export default Events;
