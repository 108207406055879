import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../utils/global-styles/theme";
import styled from "@emotion/styled";
import Face from "../Dropdown/icons/FaceIcon.js";
import OnDemand from "./Icons/OnDemand";
import CertificateIcon from "./Icons/CertificateIcon";
import RightGoToArrow from "./Icons/RightGoToArrow";
import Dropdown from "../Dropdown/Dropdown";

import { handleGoToCourseClick } from "../../utils/services/methods";
import { determineTopicIcon } from "../../utils/services/methods";

const buildRow = courseData => {
  
  const moment = require("moment"),
    courseReleaseDate = moment(courseData.releaseDate),
    courseEndDate = moment(courseData.endDate),
    currentDate = moment(new Date())
      .utcOffset("-0700")
      .format("YYYY-MM-DD"),
    hasCourseStarted = Number(courseReleaseDate.diff(currentDate, "days")) <= 0,
    hasCourseEnded = Number(courseEndDate.diff(currentDate, "days")) < 0,
    determineBottomCourseInfoDisplay = () => {
      if (courseData.masterClass) {
        return <OnDemand fillColor={theme.colors.primary} />;
      }
      if (hasCourseStarted) {
        return hasCourseEnded
          ? `Finished ${moment(courseData.endDate)
              .utcOffset("-0700")
              .format("MMMM DD")}`
          : `Ends ${moment(courseData.endDate)
              .utcOffset("-0700")
              .format("MMMM DD")}`;
      } else {
        return `Starts ${moment(courseData.startDate)
          .utcOffset("-0700")
          .format("MMMM DD")}`;
      }
    },
    StyledCourseValue = styled("p")`
      width: 20%;
      text-transform: capitalize;
      font-family: ${theme.fontFaces.headers};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        font-weight: bold;
      }
      &:first-of-type {
        display: block;
        font-weight: bold;
        font-size: 1em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: calc(30%);
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 0.75em;
        &:nth-of-type(1) {
          width: 100%;
        }
      }
    `;
  return (
    <li
      onClick={
        (hasCourseStarted && courseData.providerUrl !== "") ||
        (courseData.productType === ("Master Class" || "On-Demand") &&
          courseData.providerUrl !== "")
          ? () =>
              handleGoToCourseClick(courseData.platform, courseData.providerUrl)
          : null
      }
      css={css`
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 4em;
        border-top: 0.5px solid ${theme.colors.accent};
        padding: 11px 1em;
        &:first-of-type {
          border-top: none;
          box-shadow: 0px 12px 20px -10px #eee inset;
        }
        &:last-of-type {
          box-shadow: 0px -10px 10px -10px #eee inset;
        }
        &:hover {
          box-shadow: none;
          svg {
            path {
              fill: ${theme.colors.white};
              transition: all 0.3s ease-out;
              -webkit-transition: all 0.3s ease-out;
              -ms-transition: all 0.3s ease-out;
              -moz-transition: all 0.3s ease-out;
              -o-transition: all 0.3s ease-out;
            }
            &.svg-child-polygon-fill-primary {
              polygon {
                fill: ${theme.colors.white};
              }
            }
          }
          .hidden-child {
            display: none;
          }
          .show-child {
            display: flex;
            @media only screen and (max-width: ${theme.breakpoints.mobile}) {
              grid-area: 2 / 2 / 2 / 4;
              display: flex;
              justify-content: flex-end;
            }
          }
          .left-margin {
            margin-left: 1em;
          }
        }
        svg {
          margin-right: 0.75em;
        }
      `}
      className="secondary-background-hover"
    >
      <StyledCourseValue>
        {courseData.name.length >= 35
          ? courseData.name.slice(0, 35).trim() + "..."
          : courseData.name}
      </StyledCourseValue>
      <StyledCourseValue>{courseData.productType}</StyledCourseValue>
      <StyledCourseValue>
        {determineTopicIcon(
          courseData.courseType.toLowerCase(),
          "primary-fill path-primary-fill",
          null,
          "25px",
          "25px",
        )}
        {courseData.courseType}
      </StyledCourseValue>
      <StyledCourseValue className="primary course-list-download-link left-margin">
        {courseData.productCompleted && (
          <span
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <CertificateIcon width="35px" height="35px" />
            download certificate
          </span>
        )}
      </StyledCourseValue>
      <StyledCourseValue className="primary">
        {determineBottomCourseInfoDisplay()}
      </StyledCourseValue>
      <StyledCourseValue
        className="show-child hidden"
        css={css`
          display: flex;
          align-items: center;
          flex-direction: row;
        `}
      >
        <span
          css={css`
            margin-right: 16px;
          `}
        >
          Go to Course
        </span>
        <RightGoToArrow className="svg-child-polygon-fill-primary" />
      </StyledCourseValue>
    </li>
  );
};

function CourseList({
  courses,
  limitCourses,
  shouldCoursesBeHidden,
  gridMenuIsActive,
  setGridMenu,
  listMenuIsActive,
  setListMenu,
  order,
  setOrder,
  checkedOrder,
  currentUserCourseFormats,
  currentUserCourseTopics,
}) {
  const StyledUl = styled("ul")`
  display: flex;
        flex-direction: column;
        li {
          height: 68px;
          display: flex;
          background-color: #F7F7F7;
          &:hover {
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            cursor: pointer;
            color: ${theme.colors.white};
            .primary {
              color: ${theme.colors.white};
            }
          }
          ${courses.length > 15 &&
            `&:last-of-type {
            height: 68px;
            grid-area: 1 / 1 / span 2 / span 3;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 0.5px solid ${theme.colors.accent};
          }`}
          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(3, 33%);
            p {
              width: 100%;
              display: none;
              &:first-of-type {
                grid-area: 1 / 1 / 1 / 4;
                display: block;
              }
              &:nth-of-type(2) {
                grid-area: 2 / 1 / 2 / auto;
                display: block;
              }
              &:nth-of-type(5) {
                grid-area: 2 / 2 / 2 / 4;
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }`;
  return (
    <>
      <Dropdown
        gridMenuIsActive={gridMenuIsActive}
        setGridMenu={setGridMenu}
        listMenuIsActive={listMenuIsActive}
        setListMenu={setListMenu}
        order={order}
        setOrder={setOrder}
        checkedOrder={checkedOrder}
        currentUserCourseFormats={currentUserCourseFormats}
        currentUserCourseTopics={currentUserCourseTopics}
      />
      <StyledUl>
        {courses.map((course, index) => {
          if ((index <= 14 && limitCourses) || !limitCourses)
          return buildRow(course);
        })}
        {courses.length > 15 ? (
          <li
            css={css`
              justify-content: center;
              align-items: center;
              font-weight: bold;
            `}
            className="primary secondary-background-hover"
            onClick={() => shouldCoursesBeHidden(limitCourses ? false : true)}
          >
            {`View ${limitCourses ? "more" : "less"} past courses`}
          </li>
        ) : null}
      </StyledUl>
    </>
  );
}

CourseList.propTypes = {
  courses: PropTypes.object,
  limitCourses: PropTypes.bool,
  shouldCoursesBeHidden: PropTypes.func,
  order: PropTypes.bool,
  setOrder: PropTypes.func,
  checkedOrder: PropTypes.string,
  currentUserCourseFormats: PropTypes.arrayOf(PropTypes.string),
  currentUserCourseTopics: PropTypes.arrayOf(PropTypes.string),
  gridMenuIsActive: PropTypes.boolean,
  setGridMenu: PropTypes.func,
  listMenuIsActive: PropTypes.boolean,
  setListMenu: PropTypes.func,
};

export default CourseList;
